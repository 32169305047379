import React, { Suspense } from "react";
import { Navbar } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

// import Home from "./pages/Home";
// import Home2 from "./pages/Home2";
// import Home3 from "./pages/Home3";
// import TnC from "./pages/TnC";
// import Register from "./pages/Register";
// import Dashboard from "./pages/Dashboard";
// import CoursePage from "./pages/CoursePage";

// Lazy load the components
const Home = React.lazy(() => import("./pages/Home"));
// const Home2 = React.lazy(() => import("./pages/Home2"));
// const Home3 = React.lazy(() => import("./pages/Home3"));
const TnC = React.lazy(() => import("./pages/TnC"));
const Register = React.lazy(() => import("./pages/Register"));
const CoursePage = React.lazy(() => import("./pages/CoursePage"));
const AppRouter = React.lazy(() => import("./pages/AppRouter"));
const ReferalPage = React.lazy(() => import("./pages/ReferalPage"));
const Welcome = React.lazy(() => import("./pages/Welcome"));

// You can do the same for other components when you decide to use them

function MainRouter() {
  return (
    <Router>
      {" "}
      {/* <div style={{ height: "50%" }}> */}
      <Suspense fallback={<div>Loading...</div>}>
        {" "}
        {/* Add a loading fallback */}
        <Routes>
          <Route path="/" element={<Navigate to="/samriddhi/home" />} />
          <Route path="/samriddhi/home" element={<Home />} />
          <Route path="/samriddhi/tnc" element={<TnC />} />
          <Route
            path="/samriddhi/register"
            element={<Register method={false} />}
          />
          <Route
            path="/samriddhi/welcome"
            element={<Welcome method={false} />}
          />
          <Route
            path="/samriddhi/post_survey"
            element={<Register method={true} />}
          />
          <Route
            path="/samriddhi/referal/:rcode"
            element={<ReferalPage method={true} />}
          />
          {/* <Route path="/samriddhi/dashboard" element={<Dashboard />} /> */}
          <Route path="/samriddhi/course" element={<CoursePage />} />
          <Route path="/samriddhi/app/*" element={<AppRouter />} />

          {/* Uncomment the following when needed
          <Route path="/courses/:courseId" element={<CourseDetails />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <PrivateRoute path="/update-profile" element={<UpdateProfile />} />
          <PrivateRoute path="/dashboard" element={<Dashboard />} />
          */}

          {/* Fallback Route */}
          <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>
      </Suspense>{" "}
      {/* </div> */}
    </Router>
  );
}

export default MainRouter;
